import React, { useEffect } from 'react'
import styled from "styled-components"
import { FlexDiv } from "../design-system/FlexDiv"
// import blogPostImg from '../images/blogPostImg5.jpg'
import { Link } from "gatsby"
// import blogPostImg5 from '../images/blogPostImg5.jpg'
import blogPostImg from "../images/blogPostImg5.jpg"
import blogPostImg22 from "../images/blogPostImg2.jpg"
import blogPostImg3 from "../images/blogPostImg3.jpg"
// import blogPostImg5 from "../images/blogPostImg5.jpg";
import blogPostImg6 from "../images/pandemicera.jpg"
import "aos/dist/aos.css"

const BlogDetails = styled.p`
  color: gray;
`

const BlogTitle = styled.p`
  font-size: 1.25em;
  font-family: CentraNo2-Medium;
  margin-bottom: 0px !important;
  margin-right: 4em !important;
`
const blogExcerpt = styled.p``

const GalleryWrapper = styled.div`
  margin: auto;
  padding: 2em 0;
  display: flex;
  justify-content: space-around;
  flex-flow: column;
  max-width: 90%;
`

const CTATitle = styled.h3`
  font-family: CentraNo2-Bold;
  letter-spacing: 1px;
  font-size: 2.25rem;
  color: #212121;
  text-align: center;
  margin: 0.5em 0;
`

const CardText = styled.p`
  font-family: CentraNo2-Book;
  font-size: 0.8em;
  margin: 0;
`

const Pages = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  // line-height: 2.44;
  letter-spacing: -0.68px;
  text-align: left;
  color: #ffffff;
  margin-right: 16px;
`

const Rectangle = styled.div`
  width: 27.5em;
  height: 25em;
  opacity: 0.9;
  background-color: #1c1c1c;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  padding: 0 35px;
`

const Info = styled.div`
  margin: 0px;
  color: white;
  display: flex;
  // background: red;
  justify-content: space-between;
`

const NoPages = styled.h1`
  font-family: CentraNo2-thin;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 4.58;
  letter-spacing: -0.36px;
  text-align: left;
  color: #adadad;
`

const Arrows = styled.div`
  margin-top: 10%;
  // margin-left: 50px;
`

const GivesBackWrapper = styled.div`
  margin: auto;
  padding: 5em;
  display: inline-flex;
`

const GivesBackTitle = styled.h3`
  font-family: CentraNo2-Bold;
  font-size: 2em;
  margin-bottom: 0.5em;
`

const GivesBackText = styled.p`
  font-family: CentraNo2-Book;
  font-size: 1.15em;
  line-height: 1.75em;
  font-style: italic;
`
const GivesBackText2 = styled.p`
  font-family: CentraNo2-Book;
  font-size: 1em;
  line-height: 1.25em;
`

const ButtonLearnMore = styled.button`
  background: #000;
  color: #fff;
  padding: 15px;
  border: none;
  margintop: 20px;
  font-family: CentraNo2-Book;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  align-self: center;
`

const ButtonLearnMore2 = styled.button`
  background: #fff;
  color: #000;
  padding: 15px;
  border: none;
  margintop: 20px;
  font-family: CentraNo2-Book;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  align-self: center;
  border: 1.5px solid #000;
`
const BackSearchLink = styled.a`
  font-family: CentraNo2-Book;
  font-weight: 500;
  margin: 1em 0 2em;
  cursor: pointer;
`

export const RealEstateBrand: React.FunctionComponent = () => {
  let AOS
  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    const AOS = require("aos")
    AOS.init({
      once: true,
    })
  }, [])

  useEffect(() => {
    if (AOS) {
      AOS.refresh()
    }
  })
  return (
    <GivesBackWrapper className="blogPostWrapper">
      <FlexDiv style={{maxWidth: '75%', margin: 'auto'}} className="blogPostContainer">
        <FlexDiv
          style={{
            flexFlow: "column",
            maxWidth: "65%",
            marginRight: "7.5%",
            flex: "10",
          }}
          className="blogInner"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="2000"
        >
          <GivesBackTitle className="blogTitle">
            Breaking Through With Your Real Estate Brand
          </GivesBackTitle>
          <GivesBackText2 style={{ margin: 0 }} className="dateText">
            Oct 22, 2019, 08:15am EDT
          </GivesBackText2>

          <GivesBackText className="authorBio">
            Anthony Tropea, President & Co-Founder of Ellicott Realty Group,
            overseeing the company's portfolio: Ellicott Realty, Ellicott
            Financial, & MacKenzie Hall.
          </GivesBackText>
          <img
            src={blogPostImg}
            style={{
              width: "100%",
              margin: "auto",
              padding: "1em 0",
            }}
          />

          <GivesBackText2>
            According to the Canadian Real Estate Association (CREA), more than
            130,000 real estate brokers, agents and salespeople are registered
            CREA members. Group them into brokerage firms or real estate
            companies, with the remaining as private agents and realtors, and
            you still have several hundred companies with whom you compete in
            the marketplace. How do you break through all of them, especially if
            your real estate company is new and not yet well known?
          </GivesBackText2>

          <GivesBackText2>
            Through branding. Branding a company, large or small, takes into
            account everything from the target market to the tagline. There’s a
            lot to get just right at every step of the way, and it isn’t easy.
            But it can be done successfully to create a positive perception and
            experience in the minds of your real estate clients, which is the
            goal of branding.
          </GivesBackText2>

          <GivesBackText2>
            <strong> What’s In A Name?</strong>
          </GivesBackText2>

          <GivesBackText2>
            A name can make or break a product, good or service. In fact,
            finding the right name is a critical part of the marketing process
            when new products are introduced to the market. In the real estate
            industry, marketable names are just as important. If you want to be
            a leader in the real estate business, branding is key. Your company
            name should stand for your mission, your goals and, most
            importantly, what you want your clients to understand about your
            company. That means before you brainstorm names, take the time to:
          </GivesBackText2>
          <ul>
            <li>Define your mission statement.</li>
            <li>Identify your goals.</li>
            <li>
              Determine the message you want to communicate to your clients
              about your company.
            </li>
          </ul>
          <GivesBackText2>
            <strong>Let The Brainstorming Begin</strong>
          </GivesBackText2>
          <GivesBackText2>
            It seems like many startups have names that are a couple of words
            morphed together, or they incorporate unusual spellings, both of
            which are hard to find when searching the web. That’s a trend that I
            don't believe is in the best interest of real estate professionals.
            As one entrepreneur puts it, “Get the name right, and you get
            branding as a by-product of your advertising.”
          </GivesBackText2>

          <GivesBackText2>
            And it’s true! Think of several well-known companies and their
            respective brands, and recall their logos and slogans. You’ll
            realize you have memories, feelings, experiences and perceptions
            about those brands. Successful names also have long shelf lives.
            Make that your ultimate goal.
          </GivesBackText2>

          <GivesBackText2>
            Consider the following when creating or even revising a company
            name:
          </GivesBackText2>
          <p style={{ margin: "0px !important" }}>
            {" "}
            1. How does it sound when spoken aloud?
          </p>

          <p style={{ margin: "0px !important" }}>
            {" "}
            2. Does it imply a benefit and sound meaningful?
          </p>

          <p style={{ margin: "0px !important" }}>
            {" "}
            3. Is it a made-up/morphed word with an odd spelling?
          </p>

          <p style={{ margin: "0px !important" }}>
            {" "}
            4. Is it interesting, and does it avoid initials?
          </p>

          <p style={{ margin: "0px !important" }}>
            {" "}
            5. Is it specific enough or way too vague?
          </p>

          <p style={{ margin: "0px !important" }}>
            {" "}
            6. Can it be trademarked, if necessary?
          </p>

          <p style={{ margin: "0px !important" }}>
            7. How does it come up on Google AdWords?
          </p>

          <GivesBackText2>
            <strong> Creating The Brand</strong>
          </GivesBackText2>

          <GivesBackText2>
            The creation of the brand image and its personality are probably the
            toughest parts of branding. The logo and the tagline are,
            respectively, the visual and audio of your real estate business. The
            strongest ones are created with goals such as originality,
            creativity, honesty, relevance, passion and consistency in mind.
          </GivesBackText2>
          <GivesBackText2>
            <strong> Positioning The Brand </strong>
          </GivesBackText2>

          <GivesBackText2>
            Speaking of consistency, at my firm, for example, we carry the name
            through all of our subsidiaries, which positions the Ellicott name
            across several business areas, including the real estate and
            financial industries. We utilize the same design, style and tone
            across all the subsidiaries as well. By consistent use of the name,
            design and so on, our brand can continue to represent the mission
            and goals of our parent company and deliver on certain client
            expectations, even if an additional subsidiary comes along in the
            future.
          </GivesBackText2>
          <GivesBackText2>
            Apply these principles of consistency with your name and brand to
            build name recognition, a critical element to positioning.
            Consistent use of a specific logo and design in collateral
            materials, online and out-of-home media indicates your commitment to
            the brand and establishes it in the mind of the potential client and
            the community.
          </GivesBackText2>
          <GivesBackText2>
            Establishing your brand as a real estate agent or your company as a
            household name in the real estate industry is both an art and a
            science. Starting with a plan and purpose will lead to a more
            precise brand, which will represent your company and its ideals, and
            years of successful closings ahead.
          </GivesBackText2>
        </FlexDiv>
         <FlexDiv style={{ display: "flex", flexDirection: "column" }}>
          <FlexDiv
            style={{
              flex: "0.8",
              flexFlow: "column",
              paddingTop: "285px",
              marginLeft: "5%",
            }}
            className="relatedBlogPosts realEstateBrandSideBar"
          >
            <h2 className="mobileOnly">
            Continue Reading
          </h2>
            <div
              style={{ background: "#fff", maxWidth: "90%" }}
              className="socialCard"
            >
              <Link
                to="/pandemicera"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "#000",
                  fontFamily: "CentraNo2-Medium",
                }}
                className="imgLinkWrapper"
              >
                <img
                  src={blogPostImg6}
                  style={{ width: "100%" }}
                  className="socialImg"
                />
              </Link>
              <CardText>
                <FlexDiv>
                  <FlexDiv style={{ flexFlow: "column", alignSelf: "center" }}>
                    <FlexDiv className="innerBlogText2">
                      <BlogTitle>
                        {" "}
                        <Link
                          to="/pandemicera"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            fontFamily: "CentraNo2-Medium",
                          }}
                        >
                          The Pandemic-Era Trends Impacting Real Estate
                          Profitability
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/pandemicera"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "gray",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          July 5, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }}>
                        <Link
                          to="/pandemicera"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#212121",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          Whether you're bullish or bearish on the financial
                          impacts of Covid-19, this is undoubtedly a time to
                          profit on real estate if you know how to navigate the
                          market.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: "0.5em" }}
                      >
                        <a
                          href="https://www.forbes.com/sites/forbesrealestatecouncil/2021/12/28/the-pandemic-era-trends-impacting-real-estate-profitability/?sh=79d1c5a68d4a"
                          target="_blank"
                          style={{
                            margin: "1em 0",
                            textDecoration: "none",
                            color: "gray",
                          }}
                          className="readArticleForbes"
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
                <FlexDiv>
                  <Link
                    to="/propmanagement"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      fontFamily: "CentraNo2-Medium",
                      paddingTop: "50px",
                    }}
                    className="imgLinkWrapper"
                  >
                    <img
                      src={blogPostImg22}
                      style={{ width: "100%" }}
                      className="socialImg"
                    />
                  </Link>
                  <FlexDiv style={{ flexFlow: "column", alignSelf: "center" }}>
                    <FlexDiv className="innerBlogText2">
                      <BlogTitle>
                        {" "}
                        <Link
                          to="/propmanagement"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            fontFamily: "CentraNo2-Medium",
                          }}
                        >
                          How Property Management Can Make It Through A Pandemic
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/propmanagement"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "gray",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          July 5, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }}>
                        <Link
                          to="/propmanagement"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#212121",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          In this time of great hardship, let us be mindful that
                          we are indeed in this together, and commit to treating
                          one another with the respect that all humankind
                          deserves.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: "0.5em" }}
                      >
                        <a
                          href="https://www.forbes.com/sites/forbesrealestatecouncil/2020/07/13/how-property-management-can-make-it-through-a-pandemic/"
                          target="_blank"
                          style={{
                            margin: "1em 0",
                            textDecoration: "none",
                            color: "gray",
                          }}
                          className="readArticleForbes"
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
                <FlexDiv>
                  <Link
                    to="/truecolours"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      fontFamily: "CentraNo2-Medium",
                      paddingTop: "50px",
                    }}
                    className="imgLinkWrapper"
                  >
                    <img
                      src={blogPostImg3}
                      style={{ width: "100%" }}
                      className="socialImg"
                    />
                  </Link>
                  <FlexDiv style={{ flexFlow: "column", alignSelf: "center" }}>
                    <FlexDiv className="innerBlogText2">
                      <BlogTitle>
                        {" "}
                        <Link
                          to="/truecolours"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            fontFamily: "CentraNo2-Medium",
                          }}
                        >
                          It Is Time For Our True Colors To Illustrate True
                          Leadership And Connection
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/truecolours"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "gray",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          July 5, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }}>
                        <Link
                          to="/truecolours"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#212121",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          As a business owner, evaluate these key facets of your
                          life when creating goals this year.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: "0.5em" }}
                      >
                        <a
                          href="http://www.forbes.com/sites/forbesrealestatecouncil/2020/05/28/it-is-time-for-our-true-colors-to-illustrate-true-leadership-and-connection/"
                          target="_blank"
                          style={{
                            margin: "1em 0",
                            textDecoration: "none",
                            color: "gray",
                          }}
                          className="readArticleForbes"
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </div>
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
    </GivesBackWrapper>
  )
}
