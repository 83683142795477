import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { RealEstateBrand } from 'components/RealEstateBrand'
import { Contact } from 'components/Contact'

const RealEstateBrandPage: React.FunctionComponent = () => {
  return (
    <EllicotLayout>
      <RealEstateBrand />
      <Contact />
    </EllicotLayout>
  )
}

export default RealEstateBrandPage
